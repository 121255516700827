window.HelloworkCookieConsentCustomConfig = {
    noticeIntroductionText: "Nous utilisons des cookies pour vous suggérer les offres d’emploi les plus adaptées à votre recherche.",
    noticeModal: true,
    primaryBtnBackgroundColor: "#0C5D3A",
    privacyPolicyUrl: "/page/infos-legales.html",
    termsOfServiceUrl: "/page/cgu.html",
    noticeModalLogo: "../assets/images/logo_jobintree.png"
};

//Cookie Consent: Initialization
document.addEventListener("DOMContentLoaded", function () {
    window.helloworkCookieConsent = new HelloworkCookieConsent();
});